import decode from 'jwt-decode'

const token = {
  getTokenObject (token) {
    try {
      token = token || localStorage.getItem('token')
      if (token && token !== 'undefined') {
        return decode(token)
      } else {
        return null
      }
    } catch (err) {
      throw new Error(`Cannot get token`)
    }
  },
  loadUserFromToken (token) {
    const tokenObject = this.getTokenObject(token)

    const tenant = window.localStorage.getItem('selected-tenant')

    let user
    if (tokenObject) {
      user = {}
      // eslint-disable-next-line no-unused-vars
      for (const prop in tokenObject) {
        if (prop.endsWith('/name')) user.name = tokenObject[prop]
        if (prop.endsWith('/email')) user.email = tokenObject[prop]
        if (prop.endsWith('/company')) user.company = tokenObject[prop]
        if (prop.endsWith('/role')) user.role = tokenObject[prop]
      }
      user.id = tokenObject.sub
      user.companies = Array.isArray(user.company) ? user.company : [user.company]
      user.company = tenant && user.companies.includes(tenant) ? tenant : user.companies[0]
    }
    return user
  }
}

export default token
