import React from "react"
import PropTypes from 'prop-types'
import cx from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import footerStyle from '../../assets/jss/material-dashboard-pro-react/components/footerStyle'
import versionJson from '../../version.json'
import preval from 'preval.macro'

if (typeof versionJson.version !== 'string') {
  versionJson.version = '_DEV_'
}

const dateTimeStamp = preval`module.exports = new Date().toISOString()`

function Footer ({ ...props }) {
  const { classes, fluid, white } = props
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  })
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white
    })
  var block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white
  })
  return (
    <footer className={classes.footer}>
      <div className={container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href="https://www.nowtilus.tv/imprint" className={block}>
                Imprint
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="https://www.nowtilus.tv/privacy-policy-en-de" className={block}>
                Privacy Statement
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="https://www.nowtilus.tv" className={block}>
                Company
              </a>
            </ListItem>
          </List>
        </div>
        <p className={classes.right}>
          ver. 2.{versionJson.version.trim() + " - " + dateTimeStamp}&nbsp;
          &copy; {1900 + new Date().getYear()}{" "}
          <a href="https://www.nowtilus.tv" className={anchor} style={{ color: "inherit" }}>
            Nowtilus Onlinevertriebs GmbH
          </a>
        </p>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
}

export default withStyles(footerStyle)(Footer)
