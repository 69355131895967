import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import loginPageStyle from '../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx'
import backend from '../../utils/backend'
import { withRouter } from 'react-router-dom'
import { showSuccessMessage, showErrorMessage } from '../../components/Alerts/Alerts'
import Swal from 'sweetalert2'
import { processAutn0Response } from '../../utils/auth0'
import { componentWithContext } from '../../state/DispatchContext'
import "./style.css"
import LoginButtonWrapper from "../../components/LoginButtonWrapper";

class LoginPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cardAnimaton: 'cardHidden',
      firstname: '',
      email: '',
      password: ''
    }
  }

  async login () {
    try {
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')

      //  generate hashCode
      const verifier = (() => {
        const array = new Uint32Array(56 / 2);
        window.crypto.getRandomValues(array);
          array[1] = this.state.email.split('').reduce((prevHash, currVal) =>
              (((prevHash << 5) - prevHash) + currVal.charCodeAt(0))|0, 0);
        return Array.from(array, (dec) => ("0" + dec.toString(16)).substr(-2)).join("");
      })()

      function sha256(plain) {
        const data = new TextEncoder().encode(plain);
        // returns promise ArrayBuffer
        return window.crypto.subtle.digest("SHA-256", data);
      }

      function base64encode(a) {
        let str = "";
        let bytes = new Uint8Array(a);
        for (let i = 0; i < bytes.byteLength; i++) {
          str += String.fromCharCode(bytes[i])
        }
        return btoa(str)
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=+$/, "")
      }

      async function generateCodeChallengeFromVerifier(v) {
        const hashed = await sha256(v);
        return base64encode(hashed);
      }

      let challenge = await generateCodeChallengeFromVerifier( verifier )

      const resBody = await backend.login(this.state.email, this.state.password, {verifier, challenge})
      if (resBody.error) throw new Error('Error logging in: ' + resBody.error)
      const token = resBody.access_token
      if (!token) throw new Error('No Token Transferred')
      localStorage.setItem('token', token)
      localStorage.setItem('refresh_token', resBody.refresh_token)
      this.props.history.push('app/dashboard')
      await this.props.context.actions.users.getMe()
    } catch (e) {
      const auth0entry = processAutn0Response(e.data)
      if (auth0entry && auth0entry.offerVerification) { //TODO: we need correct error processing, with codes or so
        const result = await Swal.fire({
          type: 'warning',
          title: auth0entry.messageForUser,
          input: 'checkbox',
          inputValue: 0,
          inputPlaceholder: 'Please send me verification message again',
          confirmButtonText: 'OK'
        })
        if (result.value) {
          try {
            await backend.sendVerificationEmail(this.state.email)
            Swal.fire('You will receive a verification email, if your address exists in our database.')
          } catch (ex) {
            showErrorMessage(ex.message)
          }
        }
      } else {
        let message = (auth0entry && auth0entry.messageForUser) || e.message
        if (auth0entry && auth0entry.showDetails && e.data && e.data.data) {
          message += ` code: ${e.data.code}, error: ${e.data.data.error}, error_description: ${e.data.data.error_description}`
        }
        showErrorMessage(message)
      }
    }
  }

  async sendPasswordReset () {
    try {
      const resBody = await backend.sendPasswordReset(this.state.email)
      if (resBody.error) {
        throw new Error('Error sending password reset: ' + resBody.error)
      }
      await showSuccessMessage('Password reset mail sent. Please check your email!')
    } catch (e) {
      console.error(e.message)
      showErrorMessage(e.message)
    }
  }

  componentDidMount () {
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: '' })
      }.bind(this),
      100
    )
  }

  componentWillUnmount () {
    clearTimeout(this.timeOutFunction)
    this.timeOutFunction = null
  }

  render () {
    const { classes } = this.props

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <LoginButtonWrapper/>
        </GridContainer>
      </div>
    )
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default componentWithContext(withRouter(withStyles(loginPageStyle)(LoginPage)))
