import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import IconButton from '@material-ui/core/IconButton'
// import RefreshIcon from '@material-ui/icons/Refresh'
import { Refresh as RefreshIcon, DeleteOutline as DeleteIcon, Edit as EditIcon } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip'
// import PlayIcon from '@material-ui/icons/PlayArrow'
import backend from '../../utils/backend'
import * as constants from './Constants'
import Switch from '@material-ui/core/Switch'
import { BackendError } from '../../utils/errors'
import { showSuccessMessage, showErrorMessage, showConfirmationMessage } from '../../components/Alerts/Alerts'
import PreloaderBuffer from '../PreloaderBuffer/PreloaderBuffer'
import { TableWrapperController } from '../TableWrapper/TableWrapper'

import './style.css'

const StateButton = ({ item, toggleStatus, updatingStatusID }) => (
  <div className="ssai-table-switch">
    <Switch
      disabled={item.id === updatingStatusID}
      checked={item.state === constants.STATE_ENABLED}
      onChange={() => toggleStatus(item)}
      value="status"
      color="primary"
      size="small"
      inputProps={{ 'aria-label': 'status checkbox' }}
    />
  </div>
)

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render () {
    const { order, orderBy, fields, labels } = this.props

    const rows = fields.map((field, i) => {
      return {
        id: field,
        numeric: false,
        disablePadding: false,
        label: (labels && labels[i]) || field
      }
    })
    rows.push({
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: ''
    })
    return (
      <TableHead>
        <TableRow style={{ backgroundColor: '#e3e3e3', height: 45 }}>
          {rows.map(
            (row, i) => (
              <TableCell
                key={i}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
                style={{ textAlign: 'left' }}
              >
                { typeof row.id === 'string' ? <Tooltip
                  title={i === 0 ? '' : 'Sort'}
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    <b style={{ fontSize: '13px' }}>{typeof row.label !== 'string' ? '' : row.label}</b>
                  </TableSortLabel>
                </Tooltip> : <b style={{ fontSize: '13px' }}>{typeof row.label !== 'string' ? '' : row.label}</b> }
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  tableCellInput: {
    border: 0,
    backgroundColor: 'transparent',
    color: 'rgba(0, 0, 0, 0.87)',
    width: 'calc(100% + 10px)'
  }
})

class EnhancedTable extends React.Component {
  _isMounted = false
  constructor(props) {
    super()
    this.state = {
      order: 'desc',
      orderBy: 'createdAt',
      orderBySigned: '-createdAt',
      selected: [],
      items: null,
      page: 0,
      rowsPerPage: props.rowsPerPage || 10,
      maxColumnWidth: props.maxColumnWidth || '300px',
      alert: null,
      show: false,
      updatingStatusID: '',
      status: null,
      filterFields: [],
      filterValues: [],
      query: {}
    }
    this.controller = props.controller || new TableWrapperController()
  }
  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }
    const orderBySigned = `${order === 'asc' ? '' : '-'}${orderBy}`

    // We set the page back to the first page because nothing else makes sense
    // If you are on a particular page then sort the whole dataset, staying
    // on the page you are on would be fairly random!
    this._isMounted && this.setState({ order, orderBy, orderBySigned, page: 0 })
  }

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this._isMounted && this.setState(state => ({ selected: state.items.map(n => n.id) }))
      return
    }
    this._isMounted && this.setState({ selected: [] })
  }

  handleChangePage = (event, page) => {
    this._isMounted && this.setState({ page })
  }

  handleChangeRowsPerPage = event => {
    // We set the page back to the first page because nothing else makes sense
    // If you are on a particular page then sort the whole dataset, staying
    // on the page you are on would be fairly random!
    this._isMounted && this.setState({ rowsPerPage: event.target.value, page: 0 })
  }

  // TODO: not used, remove?
  isSelected = id => this.state.selected.indexOf(id) !== -1

  componentDidMount () {
    this._isMounted = true
    this.fetchItems()
    this.props.onRef && this.props.onRef(this)
    this.controller._isMounted = true

    // Register into table controller
    this.controller.register('reload', async () => await this.fetchItems())
  }

  componentWillUnmount () {
    this._isMounted = false
    this.props.onRef && this.props.onRef(undefined)
    this.controller._isMounted = false
  }

  shouldRefetch (prevProps) {
    const returnValue =
      this.props.search !== prevProps.search ||
      this.state.page !== prevProps.page ||
      this.state.rowsPerPage !== prevProps.rowsPerPage ||
      this.state.status !== prevProps.status ||
      this.filterIsChanged(prevProps) ||
      this.queryIsChanged()

    return returnValue
  }

  filterIsChanged(prevProps) {
    let { filterFields, filterValues } = this.state
    const result = JSON.stringify(filterFields) !== JSON.stringify(this.props.filterFields) ||
      JSON.stringify(filterValues) !== JSON.stringify(this.props.filterValues)
    return result
  }

  queryIsChanged() {
    let { query } = this.state
    const result = JSON.stringify(query) !== JSON.stringify(this.props.query)
    return result
  }

  componentDidUpdate (prevProps, prevState) {
    let { page, rowsPerPage, orderBySigned, status } = this.state
    if (
      this.props.search !== prevProps.search ||
      page !== prevState.page ||
      rowsPerPage !== prevState.rowsPerPage ||
      orderBySigned !== prevState.orderBySigned ||
      status !== this.props.status ||
      this.filterIsChanged(prevProps) ||
      this.queryIsChanged()
    ) {
      if (this.shouldRefetch(prevProps)) {
        if (this.props.search !== prevProps.search) {
          // We set the page back to the first page because nothing else makes sense
          // If you are on a particular page then search and get as subset, staying
          // on the page you are on would be fairly random! Also the subset might be 
          // smaller (e.g. have 2 pages) and so the page you were on (e.g. page 3) 
          // does not exist any more.
          this._isMounted && this.setState({ page: 0 })
        }
        this.fetchItems()
      }
    }
  }

  async fetchItems (showAlert = true) {
    return this.fetchItemsInternal({
      resource: this.props.resource,
      search: this.props.search,
      fields: this.props.fields,
      query: this.props.query,
      showAlert,
      ...this.state
    })
  }

  filterQueryGeneratory() {
    if(!this.props.filterFields) return {}
    return this.props.filterFields.reduce((result, key, index) => {
      const value = this.props.filterValues[index]
      if(!!value) result[key] = value
      return result
    }, {})
  }

  async fetchItemsInternal ({
    resource,
    search,
    page,
    rowsPerPage,
    orderBySigned,
    showAlert,
    query
  }) {
    if (resource) {
      try {
        const items = await backend.list({
          resource,
          search,
          sort: orderBySigned,
          offset: rowsPerPage * page,
          limit: rowsPerPage,
          status: this.props.status || undefined,
          ...this.props.query,
          ...this.filterQueryGeneratory()
        })
        this._isMounted && this.setState({
          items,
          status: this.props.status,
          filterFields: this.props.filterFields,
          filterValues: this.props.filterValues,
          query: this.props.query
        })
      } catch (e) {
        console.error(e)
        showAlert && showErrorMessage('Error while fetching data: ' + e)
      }
    }
  }

  hideAlert = () => {
    this._isMounted && this.setState({
      alert: null
    })
  }

  deleteItem = async (id, body) => {
    try {
      let result = await backend.delete(this.props.resource, id, body)
      if (result.code && result.code > 399) throw new BackendError(result)
      await showSuccessMessage('Your item has been deleted.')
    } catch (e) {
      showErrorMessage('Error while deleting: ' + e.message)
    }
    await this.fetchItems()
  }

  updateItem = async item => {
    try {
      this._isMounted && this.setState({ updatingStatusID: item.id })
      let result = await backend.update(this.props.resource, item)
      this._isMounted && this.setState({ updatingStatusID: '' })

      if (result.code && result.code > 399) throw new BackendError(result)
    } catch (e) {
      this._isMounted && this.setState({ updatingStatusID: '' })
      console.error(e)
      showErrorMessage('Error while saving: ' + e.message)
    }
    await this.fetchItems()
  }

  toggleStatus = async (item, preprocessor) => {
    if (preprocessor) {
      preprocessor(item)
    }
    // ** TODO:  for channels, make this use the channels service "setState" method */

    const initialState = item.state
    try {
      const state =
        item.state === constants.STATE_ENABLED
          ? constants.STATE_DISABLED
          : constants.STATE_ENABLED
      item.state = state
      await backend.request({ path: `channels/setState/${item.id}`, body: { state }, method: `PUT` })
    } catch (ex) {
      item.state = initialState
      showErrorMessage(`Error while changing channel's state: ${ex.message}`)
    }

  }

  warningWithConfirmMessage = entry => {
    if (this.props.beforeDelete) {
      this.props.beforeDelete(entry, this.deleteItem)
    } else {
      showConfirmationMessage(() => this.deleteItem(entry.id))
    }
  }

  createButtons = (entry, classes) => {
    return [
      ...(this.props.actionButtons || []),
      {
        color: 'primary',
        icon: EditIcon,
        onClick: () => {
          this.props.toggleDisplay(entry.id, entry)
        }
      },
      {
        color: 'secondary',
        icon: DeleteIcon,
        noEffect: true,
        onClick: this.warningWithConfirmMessage.bind(this, entry)
      },
    ]
    .filter((prop) => !prop.if || (typeof prop.if === 'function' && prop.if(entry)))
    .map((prop, key) => {
      return (
        <IconButton
          style={{ padding: 5 }}
          className={[classes.actionButton, "ssai-icon-button", prop.noEffect === true ? 'ssai-button-no-effect' : ''].join(' ')}
          key={key}
          onClick={() => prop.onClick(entry)}
        >
          { !!prop.icon && typeof prop.icon === 'function' && <prop.icon fontSize="small" className={classes.icon} /> }
          { !!prop.icon && typeof prop.icon === 'string' && <img alt="" src={prop.icon} height="24" /> }
        </IconButton>
      )
    })
  }

  getTable ({
    classes,
    showStateButton,
    stateChangePreprocessor,
    order,
    orderBy,
    selected,
    rowsPerPage,
    page,
    items,
    emptyRows,
    maxColumnWidth
  }) {
    let content
    if (!items) {
      content = <PreloaderBuffer />
    } else if (!Array.isArray(items)) {
      content = <p>no data found</p>
    } else {
      const columnStyle = { maxWidth: maxColumnWidth, paddingRight: '12px' }
      content = (
        <div>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={items.totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          /> */}
          <Table className={[classes.table, 'ssai-enhanced-table'].join(' ')} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={items.length}
              fields={this.props.fields}
              labels={this.props.labels}
            />
            <TableBody>
              {items.map((entry, i0) => {
                const item = this.state.items.filter(
                  row => row.id === entry.id
                )[0]
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={entry.id}
                    onClick={() => this.props.onRowClick && this.props.onRowClick(entry)}
                    style={{ backgroundColor: this.props.isCurrent && this.props.isCurrent(entry) ? 'rgba(0, 0, 0, 0.07)' : '' }}>
                    
                    {this.props.fields.map((field, i) => {
                      let cellData
                      if (typeof field === 'string') {
                        cellData = entry[field] ? entry[field].toString() : ''
                      }
                      if (typeof field === 'function') {
                        cellData = field(entry, i0, this.props, classes)
                      }
                      return (
                        <TableCell key={i} component="th" scope="row" style={columnStyle}>
                          {typeof cellData === 'string' && <input className={classes.tableCellInput} type="text" value={cellData} readOnly />}
                          {typeof cellData === 'object' && cellData}
                        </TableCell>
                      )
                    })}

                    <TableCell align="right" style={columnStyle}>
                      {showStateButton && (
                        <StateButton
                          item={item}
                          toggleStatus={() => { this.toggleStatus(item, stateChangePreprocessor) }}
                          updatingStatusID={this.state.updatingStatusID}
                        />
                      )}
                      {this.createButtons.call(this, entry, classes)}
                      {/* {this.props.setPlayerChannelId && (
                        <IconButton
                          className={classes.actionButton}
                          key='play-button'
                          onClick={() => {
                            this.props.setPlayerChannelId(entry.id)
                          }}
                        >
                          <PlayIcon fontSize="small" className={classes.icon} />
                        </IconButton>
                      )} */}
                      {this.props.resetMethod && <IconButton
                        className={[this.props.classes.actionButton, "ssai-icon-button"].join(' ')}
                        key='reset-button'
                        onClick={async () => { this.props.resetMethod(entry.id) }}
                      >
                        <RefreshIcon fontSize="small" className={this.props.classes.icon} />
                      </IconButton>}
                    </TableCell>
                  </TableRow>
                )
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={items.totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>
      )
    }

    return (
      <div>
        {content}
      </div>
    )
  }
  
  render () {
    const { classes, showStateButton, stateChangePreprocessor } = this.props
    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      items,
      maxColumnWidth
    } = this.state
    return this.getTable({
      classes,
      showStateButton,
      stateChangePreprocessor,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      items,
      emptyRows: 0,
      maxColumnWidth
    })
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(EnhancedTable)
