import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import MenuItem from '@material-ui/core/MenuItem'
import backend from "../../utils/backend"


function LogoutButton(props) {
  const { logout } = useAuth0()
  const [authProps, setAuthProps] = useState({})

  useEffect(() => {
    if (!authProps.clientId) {
      getAuthProps()
    }
  }, [authProps])

  async function getAuthProps() {
    try {
      const respons = await backend.getAuthProps()
      setAuthProps(respons.clientId)
    } catch (e) {
      console.error('Error get Auth props', e)
    }
  }

  return  (
      <MenuItem className={props.className} onClick={async () => {
        await logout({
          logoutParams: {
            returnTo: window.location.origin,
            client_id: authProps
          }
        });
        props.history.push('/')
        localStorage.removeItem('token')
        localStorage.removeItem('refresh_token')
        props.handleClose()
      }}>Logout</MenuItem>
  );
}

export default LogoutButton
