import React from "react"
import { useAuth0 } from "@auth0/auth0-react"
import "./index.css"
import Logo from "../../assets/icons/serverside-ai-logo.svg"

function LoginButtonWrapper(props) {
  const { loginWithRedirect, isLoading } = useAuth0()

  const loginHandler = async () => {
    if (!isLoading) {
      await loginWithRedirect({ appState: { returnTo: 'app/dashboard' } })
    }
  }

  return (
      <div onClick={loginHandler} className='blob'>
        <img src={Logo} className="custom-button-class" alt="SERVERSIDE.AI"></img>
      </div>
  );
}

export default LoginButtonWrapper
