import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Work from '@material-ui/icons/Work'
import Email from '@material-ui/icons/Email'
import Check from '@material-ui/icons/Check'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import Button from '../../components/CustomButtons/Button.jsx'
import CustomInput from '../../components/CustomInput/CustomInput.jsx'
import Card from '../../components/Card/Card.jsx'
import CardBody from '../../components/Card/CardBody.jsx'
import registerPageStyle from '../../assets/jss/material-dashboard-pro-react/views/registerPageStyle'
import backend from '../../utils/backend'
import { withRouter } from 'react-router-dom'
import { showErrorMessage, showSuccessMessage } from '../../components/Alerts/Alerts'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Logo from '../../assets/icons/serverside-ai-logo-full.svg'
import TermsAndConditions from "./TermsAndConditions"

class RegisterPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tacVisible: false,
      registering: false
    }
    this.termsAndConditionsClick = this.termsAndConditionsClick.bind(this)
  }

  showTermsAndConditions = () => {
    this.setState({ tacVisible: true })
  }

  hideTermsAndConditions = () => {
    this.setState({ tacVisible: false })
  }

  termsAndConditionsClick = (e) => {
    this.setState({ agreedConditions: e.target.checked })
  }

  async register () {
    if (!this.state.company) return showErrorMessage('Please enter a valid Company Name.')
    if (!this.state.email) return showErrorMessage('Please enter a valid Email.')
    if (!this.state.password) return showErrorMessage('Please enter a valid Password.')

    if (!this.state.agreedConditions) return showErrorMessage('Please accept the terms and conditions.')


    try {
      this.setState({
        registering: true
      })

      const resBody = await backend.register(this.state.company, this.state.email, this.state.password)
      if (resBody.error) {
        this.setState({
          registering: false
        })
        throw new Error('Error registering: ' + resBody.error)
      }
      showSuccessMessage('You have been successfully registered with serverside.ai. You will receive an email, which you need to confirm before you can login.')
      this.props.history.push('login')
      this.setState({
        registering: false
      })

    } catch (e) {
      let message = e.message || 'An error occured. Try again later or contact support.'
      if (message.indexOf('PasswordStrengthError') >= 0) {
        message = `Password must be at least 14 characters long, must have special characters (!@#$%^&*), lower case (a-z), upper case (A-Z) and numbers (0-9)`
      }
      console.error(message)
      const startIdx = message.indexOf('{');
      const endIdx = message.lastIndexOf('}') + 1;
      const jsonStr = message.substring(startIdx, endIdx);
      const obj = JSON.parse(jsonStr);
      const name = obj.name;
      const msg = name === 'Bad Request' ? 'Please enter a valid Email.' : obj.message;
      showErrorMessage(msg)
      this.setState({
        registering: false
      })
    }
  }
  render () {
    const { classes } = this.props
    const { registering } = this.state
    return (
      <div className={classes.container}>
        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={this.state.tacVisible}
          onClose={this.hideTermsAndConditions}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title" className={classes.termsHeader}>
            <img src={Logo} className={classes.termsHeaderImage} alt="SERVERSIDE.AI"></img>
          </DialogTitle>
          <DialogContent dividers={"true"}>

            <TermsAndConditions classes={classes} />

          </DialogContent>
          <DialogActions>
            <Button round onClick={this.hideTermsAndConditions} color="primary">
              Ok, got it
            </Button>
          </DialogActions>
        </Dialog>
        <GridContainer justify="center">

          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>Register</h2>
              <CardBody>
                <GridContainer justify="center">

                  <GridItem xs={12} sm={12} md={10}>
                    <form className={classes.form}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        inputProps={{
                          onChange: (event) => { this.setState({ 'company': event.target.value.toString().trim() }) },
                          startAdornment: (
                            <InputAdornment
                              position='start'
                              className={classes.inputAdornment}
                            >
                              <Work className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          placeholder: 'Company Name'
                        }}
                      />
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        inputProps={{
                          onChange: (event) => { this.setState({ 'email': event.target.value }) },
                          startAdornment: (
                            <InputAdornment
                              position='start'
                              className={classes.inputAdornment}
                            >
                              <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          placeholder: 'Email'
                        }}
                      />
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        inputProps={{
                          type: 'password',
                          onChange: (event) => { this.setState({ 'password': event.target.value }) },
                          startAdornment: (
                            <InputAdornment
                              position='start'
                              className={classes.inputAdornment}
                            >
                              <svg className={classes.inputAdornmentIcon} style={{color: '#495057', width: '24px'}} viewBox="0 0 24 24">
                                <path fill="currentColor" d="M12 17a2 2 0 0 1-2-2c0-1.11.89-2 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2m6 3V10H6v10h12m0-12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V10c0-1.11.89-2 2-2h1V6a5 5 0 0 1 5-5a5 5 0 0 1 5 5v2h1m-6-5a3 3 0 0 0-3 3v2h6V6a3 3 0 0 0-3-3Z"></path>
                              </svg>
                            </InputAdornment>
                          ),
                          placeholder: 'Password'
                        }}
                      />
                      <FormControlLabel
                        classes={{
                          root: classes.checkboxLabelControl,
                          label: classes.checkboxLabel
                        }}
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={this.termsAndConditionsClick}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        label={
                          <span>
                            I agree to the{" "}
                            <a href="#tnc" onClick={this.showTermsAndConditions}>terms and conditions</a>.
                          </span>
                        }
                      />
                      <div className={classes.center}>
                        <Button
                          round
                          disabled={registering}
                          color='primary'
                          onClick={this.register.bind(this)}>
                          {registering ? 'Registering...' : 'Get started'}
                        </Button>
                      </div>
                    </form>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withRouter(withStyles(registerPageStyle)(RegisterPage))
