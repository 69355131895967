import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import Assignment from '@material-ui/icons/Assignment'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import MediaDetails from '../../views/Assets/MediaDetails.jsx'
import extendedTablesStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import TableWrapper, { TableWrapperController } from '../../components/TableWrapper/TableWrapper.jsx'
import { withRouter } from 'react-router-dom'
import backend from '../../utils/backend'
import { showConfirmationMessage, showSuccessMessage, showErrorMessage } from '../../components/Alerts/Alerts'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '../../components/CustomButtons/Button.jsx'
import { MenuItem, Select, LinearProgress } from '@material-ui/core'
import { FILE_SIZE_LIMIT } from './Constants.js'


class Medias extends React.Component {
  inputUploadRef = React.createRef()
  tableRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      showDetails: this.props.showDetails,
      id: this.props.id,
      type: 'all',
      uploadProgress: false,
      uploadValue: 0,
      playMedia: false,
      mediaUrl: null
    }
    this.tableController = new TableWrapperController()
  }
  async beforeDelete (item, cb) {
    if (!item.externalResourceId) {
      return showConfirmationMessage(() => cb(item.id))
    } else {
      const otherMedias = await backend.list({ resource: 'medias', externalResourceId: item.externalResourceId })
      if (otherMedias && otherMedias.totalCount > 1) {
        return showConfirmationMessage(
          () => cb('', { externalResourceId: item.externalResourceId }),
          {
            text: `This media cannot be deleted separately. ${otherMedias.totalCount - 1} other medias from the same encoding will also be deleted.`
          }
        )
      }
      return showConfirmationMessage(() => cb(item.id))
    }
  }

  prependButtons() {
    return <div>
      <input
        style={{
          display: 'hidden',
          position: 'absolute',
          width: 0,
          height: 0,
          top: 0,
          left: 0
        }}
        type="file"
        accept={'.mp4,.mov'}
        ref={this.inputUploadRef}
        onChangeCapture={async e => {
          const file = e.target.files[0]
          const reader = new FileReader()
          const fileNameStripped = file.name.split('.')
          const { name: fileName, ext } = fileNameStripped.reduce((res, item, index) => {
            if(fileNameStripped.length - 1 === index) {
              res.ext = `.${item}`
            } else {
              res.name = res.name.length !== 0 ? `${res.name}.${item}` : item
            }
            return res;
          }, {
            name: '',
            ext: ''
          })


          if(file.size > FILE_SIZE_LIMIT) {
            showErrorMessage('Error while Saving: File size is limited to 1 GB.')
            return;
          }
          reader.readAsArrayBuffer(file)
          reader.onload = async () => {

            const form = new FormData()
            form.append('name', fileName)
            form.append('type', 'source')
            form.append('contentType', 'video')
            form.append('titleId', this.props.search)
            form.append('uploadNew', true)
            form.append('ext', ext)
            form.append('file', new Blob([reader.result], { type: `video/${ext.substring(1)}` }), fileName)

            try {
              this.setState({
                uploadProgress: true
              })
              await backend.createWithFormData(`titles/${this.props.search}/upload-media`, form, {
                onProgress: (prgg) => {
                  this.setState({
                    uploadValue: prgg
                  })
                }
              })
              showSuccessMessage()
            } catch(err) {
              showErrorMessage('Error while Saving: ' + err)
              console.log(err)
            } finally {
              this.setState({
                uploadProgress: false,
                uploadValue: 0
              })
            }

          }
        }}
      />
      <Button
        style={{ margin: '10px 10px', lineHeight: '12px', backgroundColor: '#6d8dbe' }}
        variant="contained"
        onClick={() => {
          const assetId = this.props.match.params.assetId
          this.props.history.push(`/app/assets/${assetId}/medias/new`)
        }}
      >
        + NEW MEDIA
      </Button>
      { this.state.uploadProgress && <div style={{
        width: 100,
        display: 'inline-block',
        marginLeft: 10
      }}>
        <LinearProgress variant="determinate" value={this.state.uploadValue} />
      </div> }
    </div>;
  }

  createCopyField(column) {
    return (entry, index) => <div style={{ height: '26px' }}>
      <div style={{
        whiteSpace: 'nowrap',
        display: 'inline-block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '85%'
      }}>{entry[column]}</div>
      <button style={{
        padding: '0px',
        margin: '0px',
        backgroundColor: 'transparent',
        border: '0px',
        cursor: 'pointer'
      }} onClick={() => {
        const textArea = document.createElement("textarea")


        // Place in the top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed'
        textArea.style.top = 0
        textArea.style.left = 0

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em'
        textArea.style.height = '2em'

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = 0

        // Clean up any borders.
        textArea.style.border = 'none'
        textArea.style.outline = 'none'
        textArea.style.boxShadow = 'none'

        // Avoid flash of the white box if rendered for any reason.
        textArea.style.background = 'transparent'

        textArea.value = entry[column]

        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        try {
          document.execCommand('copy')
          global.toasterController.showMessage('success', `${entry[column]} Copied!`)
        } catch (err) {
          console.log('Oops, unable to copy');
        }

        document.body.removeChild(textArea)
      }}>
        <img alt="" height="24" style={{ verticalAlign: 'initial' }} src={require('../../assets/img/buttons/copy.png')} />
      </button>
    </div>;
  }

  dateField(entry, index) {
    const currentDate = new Date(entry.createdAt)
    return (<div>{currentDate.getMonth()+1}/{currentDate.getDate()}/{currentDate.getFullYear()}</div>)
  }

  filterChanged(data) {
    this.setState({
      type: data.target.value
    })
  }

  closeMediaPlayer() {
    this.setState({
      playMedia: false,
      mediaUrl: null
    })
  }

  render () {
    const assetId = this.props.match.params.assetId
    let toggleDisplay = (id) => {
      if (id) {
        this.props.history.push('/app/assets/' + assetId + '/medias/' + id)
      } else {
        this.props.history.push('/app/assets/' + assetId)
      }
    }
    const mediaId = this.props.match.params.mediaId

    return (
      <div>
        <GridContainer>
          {
            mediaId
              ? <MediaDetails
                {...this.props}
                id={mediaId !== 'new' ? mediaId : null}
                titleId={this.props.search}
                toggleDisplay={toggleDisplay} />
              : <TableWrapper
                {...this.props}
                controller={this.tableController}
                ref={this.tableRef}
                resource={'medias'}
                toggleDisplay={toggleDisplay}
                prependButtons={this.prependButtons.bind(this)}
                search={this.props.search}
                // hideSearch={true}
                fields={['name', 'type', this.createCopyField('id'), this.dateField, this.createCopyField('url')]}
                labels={['Name', 'Type', 'Media ID', 'Created', 'URL']}
                rowsPerPage={10}
                resourceName={{ singular: 'Media', plural: 'Medias' }}
                icon={Assignment}
                maxColumnWidth={'400px'}
                beforeDelete={this.beforeDelete.bind(this)}
                filterFields={['type']}
                filterValues={[this.state.type === 'all' ? null : this.state.type]}
                actionButtons={[
                  {
                    if: entry => entry.type === 'source',
                    icon: require('../../assets/img/buttons/play.png'),
                    onClick: (entry) => {
                      this.setState({
                        playMedia: true,
                        mediaUrl: entry.url
                      })
                    }
                  },
                  {
                    icon: require('../../assets/img/buttons/copy.png'),
                    onClick: async (entry) => {
                      const item = {
                        ...entry
                      }

                      delete item.__v
                      delete item._id
                      delete item.id
                      delete item.createdAt

                      try {
                        await backend.create(`medias`, item)
                      } catch(err) {
                        console.log(err)
                      } finally {
                        await this.tableController.reload()
                        global.toasterController.showMessage('success', `Media ${entry.id} was duplicate successfully.`)
                      }
                    }
                  }
                ]}
                buttons={(
                  <div style={{
                    marginRight: 10,
                    marginTop: 20,
                    color: '#000'
                  }}>
                    Type: <Select
                      labelid="resource-select-label"
                      id="resource-select"
                      value={this.state.type}
                      onChange={this.filterChanged.bind(this)}
                      label="Type"
                      style={{
                        minWidth: '90px'
                      }}
                    >
                      <MenuItem value='all'>
                        <em>All</em>
                      </MenuItem>

                      <MenuItem value="source">
                        <em>Source</em>
                      </MenuItem>

                      <MenuItem value="intermediate">
                        <em>Intermediate</em>
                      </MenuItem>

                      <MenuItem value="deliverable">
                        <em>Deliverable</em>
                      </MenuItem>

                      <MenuItem value="internal">
                        <em>Internal</em>
                      </MenuItem>
                    </Select>
                  </div>
                )}
              />
          }
        </GridContainer >
        <Dialog
              open={this.state.playMedia}
              onClose={this.closeMediaPlayer.bind(this)}
              aria-labelledby="form-dialog-title"
              style={{ zIndex: "1009" }}
            >
          <DialogContent>
            <video width="100%" height="320" controls autoplay>
              <source src={this.state.mediaUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeMediaPlayer.bind(this)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>

    )
  }
}
export default withRouter(withStyles(extendedTablesStyle)(Medias))
