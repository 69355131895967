import React from "react"
import { useAuth0 } from "@auth0/auth0-react"

import "./index.css"


function LoginButton(props, ) {
  const { loginWithRedirect, isLoading } = useAuth0()

  return (
      <div onClick={async () => {
        if (!isLoading) {
          await loginWithRedirect({ appState: { returnTo: 'app/dashboard' } })
        }
      }} className='login-btn'>
          <props.icon />
        <div className='login-btn-text'>
          login
        </div>
      </div>
  );
}

export default LoginButton
