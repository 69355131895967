import React from "react";
import GridItem from '../Grid/GridItem.jsx'
import GridContainer from '../Grid/GridContainer.jsx'
import token from '../../utils/token.js'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input'
import { withRouter } from "react-router-dom"
import DispatchContext from '../../state/DispatchContext'

class Tenants extends React.Component {
  state = {
    companies: [],
    key: 0
  }

  static contextType = DispatchContext

  async loadCompanies() {
    const user = token.loadUserFromToken()

    // no such tenant, then take first
    const key = (user.companies.includes(user.company)) ? user.companies.indexOf(user.company) : 0

    this.setState({
      companies: user.companies,
      key
    })
  }

  componentDidMount() {
    this.loadCompanies()
  }

  render() {
    const {companies, key} = this.state
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Select
            value={companies[key]  || ''}
            onChange={async (e) => {
              let nValue = this.state.companies.indexOf(e.target.value)
              this.setState({
                key: nValue
              })
              localStorage.setItem('selected-tenant', e.target.value)
              try {
                this.context.actions.users.clearSelection()
                await this.context.actions.users.updateAppMetadata(this.context.state.users.current.app_metadata)
                    .then(() => {this.props.history.push('/app/dashboard')})
              } catch (ex) {
                return false
              }
              return true
            }}
            input={<Input id={key.toString()} />}
            style={{"margin": "0 15px 5px"}}
            name="selectTenant"
          >
            {companies.map((item, index) =>
              <MenuItem key={index} value={item}>{item}</MenuItem>
            )}
          </Select>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withRouter(Tenants)
